h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
.cont-footer-princ {
 left: 30px !important; 
}
.tbusqueda{
  font-family: 'Courier New', Courier;
}

.contenedor-cxc {
  width: 100%;
  height: 550px !important;
  background-color:  #d2ddeb;
}

  .contenedor-titulocxc {
    display: contents;
    height:200px;
  }

  .titulo-cxc {
    line-height: 30px;
    background-color: #1b538a;
    font-size: 20px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .titulo-reprecxc {
    margin-left: 20px;
    line-height: 10px;
    font-size: 15px;
    text-align: center;
    color: #1b538a;
    font-weight: bold;

  }

  .labelcedulac  {
    font-size: 20px;
    color: #050505;
    font-weight: bold;
  }

  .contenedor-cedula, .xcontenedorcedula1, .contenedorcedula2, .cont-radio {
    text-align: center;
      display: inline-block;
  }

  .contenedorcedula1 {
    margin-top: 5px !important;
  }

  .cont-listmedi {
    color: rgb(7, 5, 5);
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }
  

  .listcxc {
    background-color: #fff;
    height: 300px;
    width: auto;
    overflow: auto;
  }

  .tablacxc{
      color: #fff;
      background-color: #fff;
      width: 100%;
      border: 1px solid #7ac5cd;
      font-size: 16px;
      border-collapse: collapse;
      /* margin: 0 0 1em 0; */
      caption-side: top;
      border-collapse: collapse;  
      height: 20px;
  }
  .titucxc1, .titucxc2,.titucxc3  {
    background-color: #2bbbad ;
    color: #fff;
    font-weight: bold;
    height: 40px;
  }
  .titucxc1 {
    text-align: center;
    width: 130px;
  }
  .titucxc2 {
    text-align: center;
    width: 500px;
  }
  .titucxc3 {
    text-align: center;
    width: 140px;
  }

  .cont-datoscxc {
    height: 20px;
  }

  .datoscxc, .datoscxc1{
    color: black;
    font-weight: bold;
  }
  .datoscxc1 {
    text-align: right;
  }
  .cont-totalescxc {
    color: #fff;
    font-weight: bold;
    background-color:  #3c6cb3;
  }
  .totalescxc {
    text-align: center;
  }

  .totalescxc1, .totalescxc2, .totalescxc3 {
    text-align: right;
    font-size: 20px;
  }
.totalescxc2{
  color: red;
  font-weight: bold;
}
.totalescxc3{
  color: green;
  font-weight: bold;
}
.contenedor-pago{
  height: 400px !important;
}

.flotador{
  margin-top: 50px;
  position: absolute;
  height: 70%;
  width: 90%;
  align-Content: center;
  justify-Content: center;
  z-index:10;
  background-color:   #3c6cb3;
}

.flotador1{
  align-Self: flex-start;
  width: 100%;
}
.flotador1x{
  margin-top: 10px;
  position: absolute;
  height: 80%;
  width: 90%;
  align-Content: center;
  justify-Content: center;
  z-index:10;
  background-color:   #3c6cb3;
}

.flotador3x{
  margin-top: 10px;
  position: absolute;
  height: 60%;
  width: 90%;
  align-Content: center;
  justify-Content: center;
  z-index:10;
  background-color:   #3c6cb3;
}

.flotador3xM{
  margin-top: 0px;
  position: absolute;
  height: 70%;
  width: 97%;
  align-Content: center;
  justify-Content: center;
  z-index:10;
  background-color:   #3c6cb3;
}
.flotador3xMC{
  margin-top: 0px;
  position: absolute;
  height: 95%;
  width: 97%;
  align-Content: center;
  justify-Content: center;
  z-index:10;
  background-color:   #3c6cb3;
}
.cont-check {
  text-align: center;
}
.feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
.fechas {
  text-align: center !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.fechasL {
  text-align: center !important;
  margin-top: 120px !important;
  margin-bottom: 0px !important;
}

.fechasLC {
  text-align: center !important;
  margin-top: 60px !important;
  margin-bottom: 0px !important;
}

.titulooferta1 {
  color: #050505;
}
.ncontrol{
  color: red;
  font-size: 20px;
}
.saltoT{
  color: red;
  font-size: 20px;
  text-align: right;
}

thead tr th { 
  position: sticky;
  top: 0;
  z-index: 7;
  /* background-color: #ffffff; */
}