.cont-espera{ 
    background-color: #1b538a;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
}
.cont-titulo-espera{
    text-align: center;
}
.tituloespera{
    color: #fff;
    font-size: 25px;
    margin-top: 20px;
}
.cont-motivo{
    margin-top: 10px;
}
#fechaespera{
    margin-top: 25px;
    width: 180px;
}
.cont-general-citas{
    text-align: center;
}
.cont-span-cita{
    font-size: 25px;
    color: #028d7b;
}
.tituevo1{
    color: #fff;
    background-color: #1b538a;
    text-align: center;

}
.tablaespera1 {
    color: #fff;
    background-color: #7ac5cd;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 15px;
    text-align: center;
    border-collapse: collapse;
    margin: 0 0 1em 0;
    caption-side: top;
  }
.itemtabla1{
    font-size: 12px;
    text-align: left;
}
.itemtabla2{
    font-size: 14px;
    text-align: center;
}
.listadocasos {
    height: 310px;
    width: 100%;
    font-size: 12px;
    width: auto;
    overflow: auto;
  }
.cerrarme2{
    background-color: #fc1515;
    background-image: url('../../imagenes/borrar.png');
    background-size: 15px;
    background-repeat: no-repeat; 
    width: 20px;
    height: 20px;
    background-position:center;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block ;
    cursor: pointer;
    margin-top: 2px;
  }
  .cerrarme3{
    background-color: #fc1515;
    background-image: url('../../imagenes/borrar.png');
    background-size: 15px;
    background-repeat: no-repeat; 
    width: 20px;
    height: 20px;
    background-position:center;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block ;
    margin-top: 2px;
  }
  .cont-principal-tablas{
      margin-top: 10px;
  }
.cont-fecha{
    float: left;
}
.icono-izquierdaespera{
    float: left;
    color: red;
    font-size: 25px;
    margin-top: 30px;
    cursor: pointer;
}
.icono-izquierdac {
    margin-right: 20px;
    font-size: 20px;
    color: red;
    position: absolute;
    float: right;
    right: 0;
    top: 35px;
    cursor: pointer;

  }
  .icono-izquierdac2 {
    margin-right: 35px;
    font-size: 20px;
    color: red;
    position: absolute;
    float: right;
    right: 0;
    top: 35px;
    cursor: pointer;

  }
  .botonre {
    color: #070707;
    background-color: #fff;
    outline: none;
    border: solid 2px#C8C8C8;
    font-size: 12px !important;
    padding: 5px 5px;
    border-radius: 10px; 
    font-weight: bold;
    margin-bottom: 5%;
    margin-top: 30px;
    margin-right: 20px;

  }
.botonre:hover {
  color:  #fff;
  background-color: rgb(35, 80, 69)

}