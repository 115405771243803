.cerrarme4{
    background-color: #6aaeec;
    background-image: url('../../imagenes/editor.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    margin-top: 2px;
  }
  .cerrarme5{
    background-color: #6aaeec;
    background-image: url('../../imagenes/editor.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    cursor: pointer;
    margin-top: 2px;
  }
  .opcionno{
      margin-top: 10px;
      width: 100px;
  }
  #ordendellegada{
      height: 25px;
      width: 50px;
      margin: 0;
      padding: 0;
      text-align: left;    
  }
  .cont-fechano {
        margin-top: 10px;
        margin: 0;
}
  #fechaesperano{
      height: 25px;
      margin:0;
  }
  .cont-motivo-no{
      text-align: left;
  }

  .cont-listmedix {
        color: rgb(7, 5, 5);
        top: 40px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 50%;
        justify-content: center;
  }
  

  .listcxcx {
    background-color: #d2ddeb;
    height: 240px;
    width: auto;
    overflow: auto;
  }

  .tablacxcx{
    color: #fff;
    background-color: #fff;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 16px;
    border-collapse: collapse;
    /* margin: 0 0 1em 0; */
    caption-side: top;
    border-collapse: collapse;  
    height: 20px;
}

  .xcontenedorcedula1x {
    text-align: center;
    display: inline-block;
    margin-top: 10px !important;
  }

  .titucxc3x{
      width: 5px !important;
      text-align: center !important;
  }

  .totalescxcx1 {
      cursor: pointer;
      text-align: left !important;
  }

  .contenedorcedula1x {
      width: 500px;
  }
  .separar{
    margin-left: 50px;
  }
  .cont-personal{
    margin-bottom: 20px;
  }