.cerrarme4{
    background-color: #6aaeec;
    background-image: url('../../imagenes/editor.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    margin-top: 2px;
  }
  .cerrarme5{
    background-color: #6aaeec;
    background-image: url('../../imagenes/editor.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    cursor: pointer;
    margin-top: 2px;
  }
  .opcionno{
      margin-top: 10px;
      width: 100px;
  }
  #ordendellegada{
      height: 25px;
      width: 50px;
      margin: 0;
      padding: 0;
      text-align: left;    
  }
  .cont-fechano {
        margin-top: 10px;
        margin: 0;
}
  #fechaesperano{
      height: 25px;
      margin:0;
  }
  .cont-motivo-no{
      text-align: left;
  }

  .cont-listmedix {
        color: rgb(167, 167, 167);
        top: 40px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 80% !important;
        justify-content: center;
  }
  

  .listcxcx {
    background-color: #d2ddeb;
    height: 240px;
    width: auto;
    overflow: auto;
  }

  .tablacxcx{
    color: #fff;
    background-color: #fff;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 16px;
    border-collapse: collapse;
    /* margin: 0 0 1em 0; */
    caption-side: top;
    border-collapse: collapse;  
    height: 20px;
}
  .xcontenedorcedula1x {
    text-align: center;
    display: inline-block;
    margin-top: 10px !important;

  }
  .titucxc3x{
      width: 5px !important;
      text-align: center !important;
  }
.totalescxcx {
    cursor: context-menu !important;
    text-align: left !important;
    font-size: 12px !important;
}
.contenedorcedula1x {
    width: 700px !important;
}
#idmateria, #idfechaPlani{
  font-size: 12px;
}
#fechaDesde, #fechaHasta{
  font-size: 10px;
}
.contenedorcedula1f{
  margin-bottom: 5px;
}
.botones{
  margin-top: 10px;  
}
.listadopcDO{
  background-color: #1b538a;
  color: #fff;
}
.desde, .hasta{
  margin-top: 15px;
}


.cont-listadopc {
  color: rgb(7, 5, 5);
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.listadopcDO {
  height: 353px;
  width: 100%;
  font-size: 12px;
  width: auto;
  overflow: auto;
}

.tabla2pc {
  color: #fff;
  background-color: #7ac5cd;
  width: 100%;
  border: 1px solid #7ac5cd;
  font-size: 15px;
  text-align: center;
  border-collapse: collapse;
  margin: 0 0 1em 0;
  caption-side: top;
}

.cuerpo{
    cursor: pointer;
}

.cuerpo1{
  cursor: pointer;
}
.itemtabla{
  text-align: left;
}
.tituevo{
  color: #fff;
  background-color: #1b538a;
  text-align: left;
  padding: 10px;

}

.opcionev{
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.odd{
  background-color:  #1b538a;
}

.flotadorA{
  margin-top: 50px;
  position: absolute;
  height: 80% !important;
  width: 90%;
  align-Content: center;
  justify-Content: center;
  z-index:10;
  background-color:   #3c6cb3;
}

.flotadorR{
  margin-top: 10px;
  position: absolute;
  height: 90% !important;
  width: 90%;
  align-Content: center;
  justify-Content: center;
  z-index:10;
  background-color:   #3c6cb3;
}

.flotadorA1{
  align-Self: flex-start;
  width: 100%;
}

.groupevDO{
  background-color: #6aaeec;
  height: 150px;
}