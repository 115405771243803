h1 {
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.cont-footer-princ {
  left: 30px !important;
}

.contenedor-cxc {
  width: 100%;
  height: 550px !important;
  background-color: #d2ddeb;
}

.contenedor-titulocxc {
  display: contents;
  height: 200px;
}

.titulo-cxc {
  line-height: 30px;
  background-color: #1b538a;
  font-size: 20px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 15px;
}
.titulo-reprecxc {
  margin-left: 20px;
  line-height: 10px;
  font-size: 15px;
  text-align: center;
  color: #1b538a;
  font-weight: bold;
}

.labelcedulac {
  font-size: 20px;
  color: #050505;
  font-weight: bold;
}

.contenedor-cedula,
.contenedorcedula1,
.contenedorcedula1f,
.contenedorcedula2,
.cont-radio {
  text-align: center;
  display: inline-block;
}

.contenedorcedula1 {
  margin-top: 15px !important;
}

.contenedorcedula1f {
  margin-top: 5px !important;
}
.contenedor-cedula1k {
  display: flex;
}

.cont-listmedi {
  color: rgb(7, 5, 5);
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.listcxc {
  background-color: #fff;
  height: 300px;
  width: auto;
  overflow: auto;
}

.tablacxc {
  color: #fff;
  background-color: #fff;
  width: 100%;
  border: 1px solid #7ac5cd;
  font-size: 16px;
  border-collapse: collapse;
  /* margin: 0 0 1em 0; */
  caption-side: top;
  border-collapse: collapse;
  height: 20px;
}
.titucxc1,
.titucxc2,
.titucxc3 {
  background-color:  #3c6cb3;
  color: #fff;
  font-weight: bold;
  height: 40px;
}
.titucxc1 {
  text-align: center;
  width: 130px;
}
.titucxc2 {
  text-align: center;
  width: 500px;
}
.titucxc3 {
  text-align: center;
  width: 140px;
}

.cont-datoscxc {
  height: 20px;
}

.datoscxc,
.datoscxc1 {
  color: black;
  font-weight: bold;
}
.datoscxc1 {
  text-align: right;
}
.cont-totalescxc {
  color: #fff;
  font-weight: bold;
  background-color:  #3c6cb3;
}
.totalescxc {
  text-align: center;
}

.totalescxc1,
.totalescxc2,
.totalescxc3 {
  text-align: right;
  font-size: 20px;
}
.totalescxc2 {
  color: red;
  font-weight: bold;
}
.totalescxc3 {
  color: green;
  font-weight: bold;
}
.contenedor-pago {
  height: 400px !important;
}

.flotador1f {
  align-self: flex-start;
  width: 100%;
}
.flotador1fNc {
  align-self: flex-start;
  width: 100%;
  background-color:  #3c6cb3;
  margin-top: -30px;
}

.flotador3xf {
  margin-top: 1px;
  position: absolute;
  height: 90%;
  width: 98%;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background-color:  #3c6cb3;
}

.flotador4xf {
  margin-top: -51px;
  position: absolute;
  height: 80%;
  width: 98%;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background-color:  #3c6cb3;
}

.cont-check {
  text-align: center;
}
.feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
.fechas {
  text-align: center !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.radio {
  margin-left: 10% !important;
}
.container {
  font-size: 15px !important;
}

.cont-totales {
  text-align: right;
}

.flotador4xfFP {
  margin-left: 5%;
  margin-top: -60px;
  position: absolute;
  height: 70%;
  width: 90%;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background-color:  #3c6cb3;
}

.flotador4xfF {
  margin-left: 25%;
  margin-top: 1px;
  position: absolute;
  height: 30%;
  width: 50%;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background-color:  #3c6cb3;
}
.flotador4xfFf {
  margin-left: 0%;
  margin-top: 1px;
  position: absolute;
  height: 60%;
  width: 80%;
  align-content: center;
  justify-content: center;
  z-index: 8;
  background-color:  #3c6cb3;
}
.montoDeudor{
  font-size: 20px;
  color: red;
}

.radio-identificacion{
  margin-top: 0px;
}
.radio-identificacion2{
  background-color: #7ac5cd;
  margin-right: 2px;
}

.chskfacRe{
  height: 20px;
  width: 20px;
  margin-left: 25px;
}
.chskMonRe{
  height: 20px;
  width: 25px;
  margin-left: 185px;
  margin-top: -30px;
}
.chskMonNe{
  height: 20px;
  width: 25px;
  margin-left: 185px;
}

#transferencia, #deposito, #tarjetaVisa, #tarjetaClave, #efectivo, #cheque, #cxc, #ncredito, #canjes{
  font-size: 3px;
  text-decoration: none;
  width: 100%;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  margin: 0;
}
.fila10x-datos-antecedentesescolar {
  margin-top: 10px;
  margin-left: 20px;
}
.cont-opcion22x {
  font-size: 20px;
  width: 150px;
  margin-left: 15px;
}
.cont-opcion23x {
  text-align: center;
    display: block;
    margin-top: 21px;
}
.cont-opcion24x {
  text-align: center;
    display: inline-block;
    margin-top: 21px;
    font-size: 10px;
    height: 20px;
}
#cuentaContable {
    height: 25px;
    font-weight: bold;
}


.cont-tablamedi{
  color: rgb(7, 5, 5);
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: absolute;  
  align-Content: center;
  justify-Content: center;
  z-index:10;
}
.tablamedi1{
  background-color: #8aa7cc;;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1); 
  align-Self: flex-start;
  width: 100%; 
  margin-bottom: 15px;
}
#tbusqueda3{
  font-size: 15px;
  height: 30px;
  text-align: left;
}
#mcanjes{
  margin-top: 30px;
}

.cuentaContable{
  font-size: 18px;
}

#botonAcep{
  margin-top: 10px;
}
.montoNC{
  color: red;
  top: -10px;
  height: 30px;
  text-align: center;
  font-size: 20px;

}
.factu{
  color: rgb(0, 255, 42);
  font-size: 17px;
}
.odx{
  background-color: rgb(24, 212, 56);
}
.botonNE {
  color: #070707;
  background-color: rgb(24, 212, 56);
  outline: none;
  border: solid 2px#C8C8C8;
  font-size: 12px !important;
  padding: 5px 5px;
  border-radius: 10px; 
  font-weight: bold;
  margin-bottom: 5%;

}
.botonNE:hover {
color:  #fff;
background-color: rgb(35, 80, 69)

}