
h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
}
.cont-footer-princ {
 left: 30px !important; 
}

.cont-planillam-principal  {
    width: 100%;
    margin: 0px;
    
  }
  .contenedor-titulo {
    display: contents;
    height:200px;
  }
  
  .titulo-estudiante,.titulo-madre,.titulo-padre,.titulo-admin,.titulo-autorizacion,.titulo-noautorizado,.titulo-antecedentes,.titulo-antecedentesescolar {
    line-height: 50px;
    background-color:  #3c6cb3;
    font-size: 20px;
    text-align: center;
    color: #050505;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;
    

  }
  .titulo-notificacion {
    background-color:  #3c6cb3;
    font-size: 20px;
    text-align: center;
    color: #050505;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;
  }
 

  #identificacion,#fnacimiento,#identificacionmadre,#identificacionpadre,#telecasamadre,#telecasapadre,#fechainicio, #tiempoembarazo, #lnacimiento, #nacionalidad, #personasviven, #nacionalidadmadre, #nacionalidadpadre { 
    float: left; display: 
    block; 
    width: 95%;
  }
  .asterisco {
    float: left; 
    display: block; 
    /* width: 3%; */
    font-size: 25px;
    color: red;
    font-weight: bold;
    margin-top: 0px !important;
  }
  .asterisco2 {
    float: left; 
    display: block; 
    font-size: 20px;
    color: red;
    font-weight: bold;
  }
  .asterisco1 {
    float: left; 
    display: block; 
    width: 5%;
    font-size: 25px;
    color: red;
    font-weight: bold;
  }
  .asterisco3 {
    float: left; 
    display: block; 
    /* width: 3%; */
    font-size: 25px;
    color: red;
    font-weight: bold;
    margin-top: 20px;
  }
  .asterisco4 {
    float: left; 
    display: block; 
    /* width: 3%; */
    font-size: 25px;
    color: red;
    font-weight: bold;
    margin-top: 15px !important;
  }

  #nombre,#apellido,#domicilio,#nombrerecibo,#cedularecibo { 
    float: left; display: 
    block; 
    width: 97%;
  }
  
  #nombremadre,#apellidomadre,#ocupacionmadre,#ltrabajomadre,#telefonotrabajomadre,#celularmadre,#domiciliomadre,#correomadre {
    float: left; display: 
    block; 
    width: 97%;
  }

  #nombrepadre,#apellidopadre,#ocupacionpadre,#ltrabajopadre,#telefonotrabajopadre,#celularpadre,#domiciliopadre,#correopadre { 
    float: left; display: 
    block; 
    width: 97%;
  }

  #turno,#transporte,#personaencargada,#telecontacto { 
    float: left; display: 
    block; 
    width: 97%;
  }

  #celularperautori1,#correoperautori1,#autorizado1,#celularperautori2,#correoperautori2,#autorizado2 { 
    float: left; display: 
    block; 
    width: 97%;
  }
  #celularperautori3,#correoperautori3,#autorizado3,#celularperautori4,#correoperautori4,#autorizado4 { 
    float: left; display: 
    block; 
    width: 97%;
  }
  #celularperautori5,#correoperautori5,#autorizado5 { 
    float: left; display: 
    block; 
    width: 97%;
  }

  #nombrenoauto1,#nombrenoauto2,#nombrenoauto3,#relacionnoauto1,#relacionnoauto2,#relacionnoauto3 { 
    float: left; display: 
    block; 
    width: 97%;
  }

  #nombreemergencia,#relacionemergencia,#tlfemergencia,#nombreemergencia2,#relacionemergencia2,#tlfemergencia2,#nombreemergencia3,#relacionemergencia3,#tlfemergencia3 { 
    float: left; display: 
    block; 
    width: 97%;
  }
  #antecedentes, #tratamiento, #idiomamaterno, #conductapositiva, #conductanegativa { 
    float: left; display: 
    block; 
    width: 97%;
  }
  #nombreescuela, #rangotiempo, #atendidopor, #tiempocompartidomadre, #tiempocompartidopadre, #padeceenfermedad, #hospitalizado { 
    float: left; display: 
    block; 
    width: 97%;
  }

 #ocaciones, #operadocausa, #especifique, #especifiquemedicamento { 
  float: left; display: 
  block; 
  width: 97%;
}
#peso, #talla, #sangre, #otroalergica { 
  float: left; display: 
  block; 
  width: 90%;
}
#otroalergica { 
  float: left; display: 
  block; 
  width: 90%;
  margin-top: 20px;
}
#penicilina, #mariscos, #yodo, #merthiolate, #aspirina, #citrico, #laptosa, #otroa, #ninguna{
  font-size: 4px;
  text-decoration: none;
  width: 100%;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  margin: 0;
}
#convulsiones, #otroen, #asma, #desmayo, #diabetes, #rubeola, #papera, #sarampion, #varicela, #tosferina, #hepatitis, #amebiasis, #ningunae {
  font-size: 4px;
  text-decoration: none;
  width: 100%;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  margin: 0;
}  
  
  
  .fila1-datos-alumno,.fila2-datos-alumno,.fila3-datos-alumno,.fila4-datos-alumno,.fila5-datos-alumno {
    margin-top: 10px;
  }
  .fila1-datos-madre,.fila2-datos-madre,.fila3-datos-madre,.fila4-datos-madre,.fila5-datos-madre,.fila6-datos-madre {
    margin-top: 10px;
  }
  .fila1-datos-padre,.fila2-datos-padre,.fila3-datos-padre,.fila4-datos-padre,.fila5-datos-padre,.fila6-datos-padre {
    margin-top: 10px;
  }
  .fila1-datos-admin,.fila2-datos-admin,.fila3-datos-admin {
    margin-top: 10px;
  }
  .fila1-datos-auto,.fila2-datos-auto,.fila3-datos-auto,.fila4-datos-auto,.fila5-datos-auto,.fila6-datos-auto {
    margin-top: 10px;
  }
  .fila7-datos-auto,.fila8-datos-auto,.fila9-datos-auto,.fila10-datos-auto,.fila11-datos-auto,.fila12-datos-auto {
    margin-top: 10px;
  }
  .fila1-datos-noautorizado,.fila2-datos-noautorizado,.fila3-datos-noautorizado {
    margin-top: 10px;
  }
  .fila1-datos-emergencia,.fila2-datos-emergencia {
    margin-top: 10px;
  }
  .fila1-datos-antecedentes,.fila2-datos-antecedentes,.fila3-datos-antecedentes, .fila4-datos-antecedentes, .fila5-datos-antecedentes, .fila6-datos-antecedentes, .fila7-datos-antecedentes {
    margin-top: 10px;
  }
  .fila1-datos-antecedentesescolar,.fila2-datos-antecedentesescolar,.fila3-datos-antecedentesescolar, .fila4-datos-antecedentesescolar, .fila5-datos-antecedentesescolar, .fila6-datos-antecedentesescolar, .fila7-datos-antecedentesescolar {
    margin-top: 10px;
  }
  .fila8-datos-antecedentesescolar,.fila9-datos-antecedentesescolar,.fila10-datos-antecedentesescolar, .fila11-datos-antecedentesescolar {
    margin-top: 10px;
  }

  .radio-identificacion {
    margin-right: 25px !important;
    width: 150px !important;
  }
  .radio-noautorizado {
    padding: 0px;
  }
  .radio {
    width: 150px;
    margin-bottom: -10px;
  }
  .radio-sexo{
    margin-left: 15px;
  }
  .labelantecedentes, .labeltratamiento, .labeltipoparto, .labelantecedentesescolar, .labelcondicion, .labelvacunacion, .labelnoautorizado {
    font-size: 18px;
    margin-right: 15px;
  }
  .labelalergico, .labelenfermedades  {
    font-size: 18px;
    margin-right: 15px;
    margin-top: 20px;
  }
  .labelsexo {
    font-size: 15px;
   margin-right: 15px; 
  }
  .opsexoM, .opcedula, .opcedulaM, .opcedulaP, .opantecedentessi, .optratamientosi, .optipopartonormal, .opexperienciasi, .opcondicionsi, .opmedicamentosi, .opvacunacionsi, .ophospitalizadosi, .opoperadosi, .oprepresentantesi, .opnoautorizadosi, .opredessocialessi, .opevangeliosi {
    margin-right: 1px;   
    display: inline;
    padding: 5px;
    width: 10px;
    margin: 10px 0;
    font-size: 15px;
}
  
  .opsexo, .opantecedentesno, .optratamientono, .optipopartocesarea, .opexperienciano, .opcondicionno, .opmedicamentono, .opvacunacionno, .ophospitalizadono, .opoperadono, .oprepresentanteno, .opnoautorizadono,.opredessocialesno, .opevangeliono {
    margin-left: 10px;  
    display: inline;
    padding: 5px;
    width: 25px;
    margin: 10px 0;
    font-size: 15px;
  }
  .fnacimiento {
    margin-right: 15px;
  }
  #edad {
    width: 80px;
    height: 35px;
  }
  .fini {
    width: 160px;
    color: #515151;
    font-weight: bold;
    font-size: 17px;
  }
  .retirado {
    color: red;
    font-weight: bold;
    font-size: 18px;
  }

  .fechainicio {
    margin-left: 15px;
  }
  .cont-opcion2 {
    margin-bottom: 15px;
    text-align: center;
    color: #fff;
    font-size: 12px;
} 
.cont-opcion22 {
  margin-bottom: 15px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  margin-right: 12px;
}
.cont-opcion22e {
  margin-bottom: 15px;
  text-align: center;
  color: #fff;
  font-size: 8px;
  
}

#madre, #padre, #nana, #chofer, #otro {
  width: 100%;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  margin: 0;
  font-size: 14px;
  height: 25px;
  } 
  .opcion {
    color: rgb(14, 13, 13);
    font-weight: 150;
    font-weight: bold;
  }
  .opcion2 {
    color: rgb(14, 13, 13);
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    width: 60px;
  }
  
  #admin{
    font-size: 16px;
    width: 260px;
    height: 35px;
    margin-left: 15px;font-size: 18px;

}
#nombre {
  float: left; display: 
  block; 
  width: 97%;
}
.botonm {
  margin-top: 25px !important;
  margin-right: 15px;
}

.botonOf {
  color: rgba(182,184,214,1);
  background: transparent;
  outline: none;
  border: solid 1px rgb(18, 149, 167);;
  font-size: 16px;
  padding: 5px 5px; 
  border-radius: 10px; 
  margin-right: 20px; 
}    
.botonem {

  color: red; 
}
.botonem:hover {
  background-color: red;
  color: #fff; 
}

input[type="date"]
{
    display:block;
  
    /* Solution 1 */
     -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em; 
  
    /* Solution 2 */
    /* min-width: 96%; */
}

.contenedor-titulox {
  display: contents;
  height:200px;
  z-index:10;
  
}

.titulo-notificacionx {

  background-color: #f50909;
  font-size: 20px;
  text-align: center;
  color: #050505;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-top: -10%;
  z-index:100;
}

