#numeroEstatusR, #sucursal, #medico, #ordenar, #casos1{
    width: 450px;
    height: 30px;
}
.titulo-desde, .titulo-hasta  {
    color: #030303;
    font-weight: bold;
    padding: 0%;
    margin: 0%;
    margin-top: 5px;
  }
  .fechas1, .fechas2{
      margin-bottom: 20px;
  }
  #paciente1{
      margin-left: 60px;
      margin-top: 30px;
  }