h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
}
.cont-footer-princ {
 left: 30px !important; 
}

.contenedor-planillai {
  width: 100%;
  height: 100%;
  background-color: #d2ddeb;
}

.contenedor-cedula  {
    width: 100%;
    margin: 0px;
    text-align: center;
    display: inline-block;
}
.contenedorp {
    display: grid;
    justify-content: center;
}
.titulo-planillai {
    line-height: 50px;
    background-color: #1b538a;
    font-size: 15px;
    text-align: center;
    color: #050505;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;
}

.titulocasosAS {
  line-height: 50px;
  /* background-color: #1b538a; */
  font-size: 25px;
  text-align: center;
  color: #050505;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 15px;
}

.labelcedulac  {
    font-size: 18px;
    color: #050505;
    font-weight: bold;
}

.botonOf {
  color: rgba(182,184,214,1);
  background: transparent;
  outline: none;
  border: solid 1px rgb(18, 149, 167);;
  font-size: 16px;
  padding: 5px 5px; 
  border-radius: 10px; 
  margin-right: 20px; 
}    

  .boton {
    color: #070707;
    background-color: #fff;
    outline: none;
    border: solid 2px#C8C8C8;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    font-weight: bold;
    margin-bottom: 5%;
    margin-right: 15px;

  }
.boton:hover {
  color:  #fff;
  background-color: rgb(35, 80, 69)

}
.renglon{
    height: 30px;

}
.odd{
    background-color:  #3c6cb3;
}

.tabla {
    color: #fff;
    background-color: #3c6cb3;
    width: 100%;
    border: 1px solid  #3c6cb3;
    text-align: left;
    border-collapse: collapse;
    margin: 0 0 1em 0;
    caption-side: top;
  }
  .fechas{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  #codigo3 {
    width: 150px;
    height: 35px;
    margin-right: 25px;
    font-size: 18px;
  }

  #codigo4 {
    width: 150px;
    height: 35px;
    font-size: 20px;
  }
  .container {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
  }
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .flotador1xp {
    margin-top: 50px;
    position: absolute;
    height: 250px;
    width: 90%;
    align-Content: center;
    justify-Content: center;
    z-index: 10;
    background-color: #d2ddeb;
}

.tablamedi1z {
  background-color: #8aa7cc;
  box-shadow: 0px 1px 1px 5px rgb(182 184 214);
  align-Self: flex-start;
  width: 100%;
  margin-bottom: 15px;
  height: 280px;
}
  
.tablamedi1 {
  background-color:  #3c6cb3 !important;
}

.listmediAS {
  height: 80px;
  width: 100%;
  width: auto;
  overflow: auto;
}
.cont-tablamediCA{
  color: rgb(7, 5, 5);
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: absolute;  
  align-Content: center;
  justify-Content: center;
  z-index:20;
}

.cont-tablamediSA{
  color: rgb(7, 5, 5);
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: absolute;  
  align-Content: center;
  justify-Content: center;
  z-index:50;
}

.botonCalendario{
margin-top: 25px;
} 

.contenedoasociar{
  margin-bottom: 25px;
}
.contenedoasociar2{
  margin-top: 40px;
  margin-bottom: 40px;
}

#imagen1, #imagen2, #imagen3, #imagen4{
  font-size: 3px;
  text-decoration: none;
  width: 100%;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  margin: 0;
}
.cont-opcion22i {
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  margin-right: 12px;
}

