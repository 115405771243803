h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
.cont-footer-princ {
 left: 30px !important; 
}
.titulocasos{
  font-size: 20px !important;
  color: #1b538a;
}

.contenedor-cxc {
  width: 100%;
  height: 550px;
  background-color:  #d2ddeb;
}

  .contenedor-titulocxc {
    display: contents;
    height:200px;
  }

  .titulo-cxc {
    line-height: 30px;
    background-color: #1b538a;
    font-size: 20px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .titulo-reprecxc {
    margin-left: 20px;
    line-height: 10px;
    font-size: 15px;
    text-align: center;
    color: #1b538a;
    font-weight: bold;

  }

  .labelcedulac  {
    font-size: 20px;
    color: #050505;
    font-weight: bold;
  }

  .contenedor-cedula {
    margin: 0 auto;
  }
  .cedulac {
    margin: 0 auto;
    justify-Content: center;
    display: flex;
  }
  #correoI{
    width: 480px;
  }
  .cont-tablacorreo2 {
    color: rgb(7, 5, 5);
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 30%;
  width: 100%;
  /* position: absolute; */
  /* align-Content: center; */
  justify-Content: center;
  /* z-index: 10; */
}
.tablacorreo2{
  /* background-color: #8aa7cc;;
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1); */
  align-Self: flex-start;
  width: 100%;
  margin-bottom: 15px;
}
.cont-listcorreo2 {
  color: rgb(7, 5, 5);
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}


.listcorreo2 {
  background-color: #7ac5cd;
  height: 85px;
  width: auto;
  overflow: auto;
}

.tablacorreo2x{
  color: #fff;
  background-color: #398891;
  width: 100%;
  border: 1px solid #7ac5cd;
  font-size: 12px;
  height: 20px;
  /* margin: 0 0 1em 0; */
  caption-side: top;
  border-collapse: collapse;  
}

.cont-tablacorreo {
  color: rgb(7, 5, 5);
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 27%;
  width: 100%;
  /* position: absolute; */
  /* align-Content: center; */
  justify-Content: center;
  /* z-index: 10; */
}
  .tablacorreo1{
    /* background-color: #8aa7cc;;
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1); */
    align-Self: flex-start;
    width: 100%;
    margin-bottom: 15px;
  }

  .cont-listcorreo {
    color: rgb(7, 5, 5);
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 70%;
    justify-content: center;
  }
  

  .listcorreo {
    background-color: #7ac5cd;
    height: 90px;
    width: auto;
    overflow: auto;
  }

  .tablacorreo{
      color: #fff;
      background-color: #398891;
      width: 100%;
      border: 1px solid #7ac5cd;
      font-size: 12px;
      height: 20px;
      /* margin: 0 0 1em 0; */
      caption-side: top;
      border-collapse: collapse;  
  }

  .titucxc1, .titucxc2,.titucxc3  {
    background-color: #2bbbad ;
    color: #fff;
    font-weight: bold;
    height: 40px;
  }
  .titucxc1 {
    text-align: center;
    width: 130px;
  }
  .titucxc2 {
    text-align: center;
    width: 500px;
  }
  .titucxc3 {
    text-align: center;
    width: 140px;
  }

  .cont-datoscxc {
    height: 20px;
  }

  .datoscxc, .datoscxc1{
    color: black;
    font-weight: bold;
  }
  .datoscxc1 {
    text-align: right;
  }
  .cont-totalescxc {
    color: #fff;
    font-weight: bold;
    background-color:  #3c6cb3;
  }
  .totalescxc {
    text-align: center;
  }

  .totalescxc1, .totalescxc2, .totalescxc3 {
    text-align: right;
    font-size: 20px;
  }
.totalescxc2{
  color: red;
  font-weight: bold;
}
.totalescxc3{
  color: green;
  font-weight: bold;
}
.contenedor-pago{
  height: 400px !important;
}

.flotadorc{
  margin-top: 8%;
  position: absolute;
  height: 65%;
  width: 95%;
  align-Content: center;
  justify-Content: center;
  z-index:30;
  background-color:   #3c6cb3;
  text-align: center;
}

.flotadorc1{
  text-align: center;
  align-Self: flex-start;
  width: 100%;
}

.flotadora{
  margin-top: 2px;
  position: absolute;
  height: 80%;
  width: 95%;
  align-Content: center;
  justify-Content: center;
  z-index:30;
  background-color:   #3c6cb3;
  text-align: center;
}

.flotadora1{
  text-align: center;
  align-Self: flex-start;
  width: 100%;
}
.cont-check {
  text-align: center;
}
.botonc {
  color: #070707;
  background-color: #fff;
  outline: none;
  border: solid 2px#C8C8C8;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 10px; 
  font-weight: bold;
  margin-top: 7%;
  margin-bottom: 5%;
  margin-right: 15px;

}
.botonc:hover {
color:  #fff;
background-color: rgb(35, 80, 69)

}