.cerrarme4{
    background-color: #6aaeec;
    background-image: url('../../imagenes/editor.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    margin-top: 2px;
  }
  .cerrarme5{
    background-color: #6aaeec;
    background-image: url('../../imagenes/editor.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block;
    cursor: pointer;
    margin-top: 2px;
  }
  .opcionno{
      margin-top: 10px;
      width: 100px;
  }
  #ordendellegada{
      height: 25px;
      width: 50px;
      margin: 0;
      padding: 0;
      text-align: left;    
  }
  .cont-fechano {
        margin-top: 10px;
        margin: 0;
}
  #fechaesperano{
      height: 25px;
      margin:0;
  }
  .cont-motivo-no{
      text-align: left;
  }

  .cont-listmedixB {
        color: rgb(167, 167, 167);
        top: 40px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 95% !important;
        justify-content: center;
  }
  

  .listcxcxB {
    background-color: #d2ddeb;
    height: 350px;
    width: auto;
    /* overflow: auto; */
  }

  .tablacxcx{
    color: #fff;
    background-color: #fff;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 16px;
    border-collapse: collapse;
    /* margin: 0 0 1em 0; */
    caption-side: top;
    border-collapse: collapse;  
    height: 20px;
}
  .xcontenedorcedula1x {
    text-align: center;
    display: inline-block;
    margin-top: 10px !important;

  }
  .titucxc1B,
.titucxc2,
.titucxc3 {
  background-color:  #0e57c5;
  color: #fff;
  font-weight: bold;
  height: 40px;
}
  .titucxc3xpe{
    width: 120px !important;
    text-align: center !important;
    background-color:  #3c6cb3;
}
  .titucxc1{
    width: 325px !important;
  }
  .titucxc3x{
      width: 5px !important;
      text-align: center !important;
  }
.totalescxcx {
    cursor: context-menu !important;
    text-align: left !important;
    font-size: 12px !important;
}
.contenedorcedula1x {
    width: 700px !important;
}




.cont-diasterapia2{
  background-color: #d2ddeb;
  margin-top: 50px;
  
}

.este {
  height: 25em;
  line-height: 1em;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  border: 1px solid;
}
.cont-contenedor{
  padding: 0px !important;
}
.mostrarFechas {
  font-size: 20px;
  color: #bd1de6;
}
.cont-forma{
  margin-top: 20px;
}
/* .cont-Boton {
   margin-top: 10px;
  padding-left: 20px; 
} */
.botonff1{
  color: #070707;
  background-color: #fff;
  outline: none;
  border: solid 2px#C8C8C8;
  font-size: 15px !important;
  padding: 8px 8px;
  border-radius: 10px; 
  font-weight: bold;
  margin-bottom: 5%;
  margin-right: 10px;
}
.botonff2:hover {
  color:  #fff;
  background-color: #3b76c4;
  }
.botonff2 {
  color: #070707;
  background-color: #fff;
  outline: none;
  border: solid 2px#C8C8C8;
  font-size: 15px !important;
  padding: 8px 8px;
  border-radius: 10px; 
  font-weight: bold;
  margin-bottom: 5%;
}
.botonff1:hover {
color:  #fff; 
background-color: #3b76c4;
}
.dispo1{
  color: rgb(23, 228, 149);
  font-weight: bold;
}
.contenedor-casos {
margin-top: 20px;  
}
.caja-header{
  width: 200px;
  height: 50px;
  background-color: #b2a9b5;
  margin: 10px;
}
.caja-contenido{
  width: 200px;
  height: 50px;
  background-color: #d6a4e7;
  margin-bottom: 30px;
  margin: 10px;
}
.flotador3xfB {
  margin-top: 1px;
  position: absolute;
  height: 40%;
  width: 98%;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background-color:  #3c6cb3;
}
.flotador1fB {
  align-self: flex-start;
  width: 100%;
}
.opcion2cB{
  color: rgb(14, 13, 13);
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  width: 170px;
}
.botonxB{
  height: 20px;
}